@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "poppins";
  src: local("poppins"),
    url("./fonts/poppins/Poppins-Regular.ttf") format("truetype");
}

@keyframes wave {
  0% { transform: rotate( 0.0deg) }
   10% { transform: rotate(14.0deg) }
   20% { transform: rotate(-8.0deg) }
   30% { transform: rotate(14.0deg) }
   40% { transform: rotate(-4.0deg) }
   50% { transform: rotate(10.0deg) }
   60% { transform: rotate( 0.0deg) }
  100% { transform: rotate( 0.0deg) }
}
